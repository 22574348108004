import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import FooterTwo from "../elements/FooterTwo";
import Team from "../elements/TeamTwo";
import Accordion01 from "../elements/Accordion";
import { FiChevronUp } from "react-icons/fi";
import { MdSpeakerNotes, MdLanguage, MdPeople } from "react-icons/md";
import HeaderThree from "../elements/HeaderThree";
import ContactOne from "../elements/ContactOne";
import { HashLink as Link } from "react-router-hash-link";

import i18next from "../i18n";

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--32",
    category: "",
    title: i18next.t("titel"),
    description: i18next.t("untertitel"),
    buttonText: i18next.t("kontaktCTA"),
    buttonLink: "/#contato",
  },
];

const slideSlick = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  fade: true,
  easing: "fade",
  adaptiveHeight: true,
};

const ServiceListOne = [
  {
    icon: <MdSpeakerNotes />,
    title: i18next.t("service01titel"),
    description: i18next.t("service01untertitel"),
  },
  {
    icon: <MdPeople />,
    title: i18next.t("service02titel"),
    description: i18next.t("service02untertitel"),
  },
  {
    icon: <MdLanguage />,
    title: i18next.t("service03titel"),
    description: i18next.t("servie03untertitel"),
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <Fragment>
        {/* Start Header Area  */}
        <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <Link
                                smooth
                                className="rn-button-style--2 btn-solid"
                                to={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="text-center service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start About Area  */}
        <div id="sobrenós" className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{i18next.t("ueberuns")}</h2>
                    {/* <p className="description">Wir erbringen klassische Dienstleistungen innovativ.</p> */}
                  </div>
                  <br></br>
                  <p>
                    Somos um escritório de advocacia austríaco que representa
                    clientes em português na Áustria. Nossa experiência abrange
                    a compra de imóveis, bem como a assistência a nossos
                    clientes em assuntos de direito de família, tais como
                    divórcio, pensão alimentícia, direitos de visita e de
                    contato com crianças e guarda conjunta. <br></br>
                    <br></br>
                    Também representamos empresas em processos de litígio civil
                    e arbitragem na Áustria. Nós nos esforçamos para explicar a
                    situação jurídica austríaca a nossos clientes de forma clara
                    e compreensível e para representá-los da melhor maneira
                    possível perante todas as instituições, autoridades e
                    tribunais. <br></br> <br></br>
                    Se você nos enviar uma consulta, receberá rapidamente uma
                    resposta com informações sobre os custos previstos em
                    relação à representação, para que você possa ter uma visão
                    geral de nossos procedimentos de trabalho. <br></br>{" "}
                    <br></br>
                    Nosso emblema Avvolingua representa a combinação de
                    profissionalismo jurídico e conhecimento de línguas
                    estrangeiras.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-4.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start About Area  */}
        <div id="serviços" className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-5.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{i18next.t("leistungen")}</h2>
                    <p>{i18next.t("leistungenUntertitel")}</p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Team Area  */}
        <div id="equipa" className="rn-team-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">{i18next.t("team")}</h2>
                  <p>{i18next.t("teamUntertitel")}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team
                column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
                teamStyle=""
                item="4"
              />
            </div>
          </div>
        </div>

        {/* End Team Area  */}

        {/* Start COntact Area */}
        <section id="contato">
          <div className="fix">
            <div className="rn-contact-area ptb--120 bg_color--1">
              <ContactOne />
            </div>
          </div>
        </section>

        {/* End COntact Area */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
