import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import i18next from "../i18n";

class Accordion01 extends Component {
  render() {
    return (
      <Accordion className="accodion-style--1" preExpanded={"1"}>
        <AccordionItem uuid={"1"}>
          <AccordionItemHeading>
            <AccordionItemButton>{i18next.t("leistung01")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{i18next.t("")}</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>{i18next.t("leistung02")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{i18next.t("")}</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>{i18next.t("leistung03")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{i18next.t("")}</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>{i18next.t("leistung04")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{i18next.t("")}</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>{i18next.t("leistung05")}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{i18next.t("")}</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}

export default Accordion01;
