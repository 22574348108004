import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import i18next from "../i18n";

const ContactOne = () => {
  const form = useRef();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showMailSent, setShowMailSent] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
    emailjs
      .sendForm(
        "service_akm8r1h",
        "template_st06pm7",
        form.current,
        "user_FymftidrDSgY7JU2H2YJE"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowMailSent(true);
        },
        (error) => {
          console.log(error.text);
          setShowError(true);
        }
      );
  };
  if (!showConfirmation) {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{i18next.t("kontaktCTA")}</h2>
                <p className="description">
                  Por telefone:{" "}
                  <a href="tel:+43 316 386838">+43 316 386838</a> ou por e-mail:
                  <a href="mailto:office@schoberl.eu">
                    {" "}
                    office@schoberl.eu
                  </a>{" "}
                </p>
              </div>
              <div className="form-wrapper">
                <form ref={form} onSubmit={sendEmail}>
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      placeholder={i18next.t("kontaktName")}
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="text"
                      name="email"
                      id="item02"
                      placeholder={i18next.t("kontaktMail")}
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      placeholder={i18next.t("kontaktBetreff")}
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="message"
                      placeholder={i18next.t("kontaktNachricht")}
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                  >
                    {i18next.t("kontaktSenden")}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src="/assets/images/about/about-6.jpg" alt="trydo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (showError) {
      return (
        <div className="section-title service-style--3 text-center mb--25">
          <p>
          A mensagem não pôde ser enviada. Por favor, entre em contato conosco diretamente por e-mail.
          </p>
        </div>
      );
    } else if (showMailSent) {
      return (
        <div className="section-title service-style--3 text-center mb--25">
          <p>Mensagem enviada com sucesso. Muito obrigado!</p>
        </div>
      );
    } else {
      return (
        <div className="section-title service-style--3 text-center mb--25">
          <p>A mensagem é enviada...</p>
        </div>
      );
    }
  }
};
export default ContactOne;
