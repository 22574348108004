import React, { Component, Fragment } from "react";
import HeaderFour from "../elements/HeaderFour";
import i18next from "../i18n";

class Impressum extends Component {
  render() {
    return (
      <Fragment>
        {/* Start Header Area  */}
        <HeaderFour
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-black"
        />
        {/* End Header Area  */}
        {/* Start About Area  */}
        <div id="about" className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{i18next.t("impressum")}</h2>
                    {/* <p className="description">Wir erbringen klassische Dienstleistungen innovativ.</p> */}
                  </div>
                  <br></br>
                  <p>
                    <b> Firmenwortlaut</b>
                    <br></br>
                    Rechtsanwaltskanzlei Dr. Christian Schoberl
                    <br></br>
                    <b>Firmenbuchgericht</b>
                    <br></br>
                    Landesgericht für ZRS Graz<br></br>
                    <b>Ort der Niederlassung</b>
                    <br></br>
                    Alberstraße 9/HP/2, A-8010 Graz<br></br>
                    <b>Kontaktdaten</b>
                    <br></br>
                    +43 316 386838<br></br>
                    office@schoberl.eu<br></br>
                    <b>Kammer / Berufsverband-Zugehörigkeit(en)</b>
                    <br></br>
                    Rechtsanwaltskammer Steiermark<br></br>
                    <b>Zusätzliche Informationspflicht</b>
                    <br></br>
                    Standesrecht: Rechtsanwaltsordnung (RAO), Disziplinarstatut
                    für Rechtsanwälte (DSt) Richtlinien für die Ausübung des
                    Rechtsanwaltsberufes (RL-BA) www.rechtsanwaelte.at<br></br>
                    <b>Aufsichtsbehörde</b>
                    <br></br>
                    Rechtsanwaltskammer Steiermark Verbraucher haben die
                    Möglichkeit, Beschwerden an die
                    Online-Streitbeilegungsplattform der EU zu richten:
                    <a href="http://ec.europa.eu/odr">
                      {" "}
                      http://ec.europa.eu/odr
                    </a>
                    . Sie können allfällige Beschwerde auch an die oben
                    angegebene E-Mail-Adresse senden.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-7.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
      </Fragment>
    );
  }
}
export default Impressum;
