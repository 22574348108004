import React from "react";
import i18next from "../i18n";

let data = [
  {
    images: "01",
    title: i18next.t("ma01Name"),
    designation: i18next.t("ma01Text"),
  },
  {
    images: "02",
    title: i18next.t("ma02Name"),
    designation: i18next.t("ma02Text"),
  },
  {
    images: "03",
    title: i18next.t("ma03Name"),
    designation: i18next.t("ma03Text"),
  },
  {
    images: "04",
    title: i18next.t("ma04Name"),
    designation: i18next.t("ma04Text"),
  },
];

const TeamTwo = (props) => {
  const itemSlice = data.slice(0, props.item);
  return (
    <div className="row">
      {itemSlice.map((value, i) => (
        <div className={`${props.column}`} key={i}>
          <div className={`team-static ${props.teamStyle}`}>
            <div className="thumbnail">
              <img
                src={`/assets/images/team/team-${value.images}.jpg`}
                alt="Blog Images"
              />
            </div>
            <div className="inner">
              <div className="content">
                <h4 className="title">{value.title}</h4>
                <p className="designation">{value.designation}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default TeamTwo;
