import React, { Component, Fragment } from "react";
import HeaderFour from "../elements/HeaderFour";
import i18next from "../i18n";

class Page404 extends Component {

  render() {
    return (
      <Fragment>
        {/* Start Header Area  */}
        <HeaderFour
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-black"
        />
        {/* End Header Area  */}
        {/* Start About Area  */}
        <div id="about" className="rn-about-area ptb--120">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{i18next.t('fehlerTitel')}</h2>
                    <br></br>
                    <p className="description">
                      <a href="/">{i18next.t('fehlerText')}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="error-image">
                  <img
                    className="w-100"
                    src="/assets/images/error.png"
                    alt="'Error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
      </Fragment>
    );
  }
}
export default Page404;
