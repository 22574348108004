import React, { Component } from "react";
import Scrollspy from "react-scrollspy";
import { HashLink as Link } from "react-router-hash-link";
import i18next from "../i18n";

class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { color = "default-color" } = this.props;
    let logoUrl = (
      <img
        src="/assets/images/logo/logo-symbol-dark.png"
        alt="Digital Agency"
      />
    );

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink}>{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy
                className="mainmenu"
                items={["sobrenós", "serviços", "equipa", "contato"]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <Link smooth to="/#sobrenós">
                    {i18next.t("ueberuns")}
                  </Link>
                </li>
                <li>
                  <Link smooth to="/#serviços">
                    {i18next.t("leistungen")}
                  </Link>
                </li>
                <li>
                  <Link smooth to="/#equipa">
                    {i18next.t("team")}
                  </Link>
                </li>
                <li>
                  <Link smooth to="/#contato">
                    {i18next.t("kontakt")}
                  </Link>
                </li>
              </Scrollspy>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
