// React Required
import React, { Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Create Import File
import "./index.scss";

import CorporateBusiness from "./pages/CorporateBusiness";
import Page404 from "./pages/Page404";
import Impressum from "./pages/Impressum";

import "./i18n";

// loading component for suspense fallback
const Loader = () => (
  <div className="App">  
    <div>loading...</div>
  </div>
);

class Root extends Component {
  render() {
    return (
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={CorporateBusiness}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/impressum`}
              component={Impressum}
            />

            <Route path={`${process.env.PUBLIC_URL}/404`} component={Page404} />
            <Route component={Page404} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
